// .ferment{
//   display: inline-block;
//   vertical-align: middle;
//   position: relative;
//   text-align: center;
//   .basename{
//     font-size: 0.5rem;
//     line-height: 0.5rem;
//     font-weight: bold;
//     text-transform: uppercase;
//     margin-top: -0.25rem;
//   }
//   .increment{
//     font-size: 0.5rem;
//     line-height: 0.5rem;
//   }
// }
.asAFerment{
  min-width: 50px !important;
  max-width: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  border-radius: 50% !important;
  border: 2px solid #fff;
  padding: 0.4rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  .dluo{
    margin-top: 0.2rem;
    font-size: 0.4rem;
    white-space: nowrap;
    small{
      font-size: 0.4rem;
      line-height: 0.4rem;
      display: block;
    }
    span{
      font-size: 0.5rem;
      line-height: 0.6rem;
      display: block;
    }
  }

  .ferment-type{
    display: block;
    // height: 1.2rem;
  }  

  .quantity{
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0.6rem;
    line-height: 0.6rem;
    opacity: 0.8;

    border: 1px solid grey;
    border-radius: 50%;
    padding: 2px;
    background: #fff;
    min-width: 8px;
  }


  .freeze{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0.6rem;
    line-height: 0.6rem;
    opacity: 0.8;

    border: 1px solid #3D8CFF;
    border-radius: 50%;
    padding: 0px;
    background: #fff;
    min-width: 8px;
    color: #3D8CFF;
    .MuiSvgIcon-root{
      font-size: 0.6rem;
      line-height: 0.6rem;
    }
  }

  .id{
    font-size: 0.5rem;
    opacity: 0.7;
  }

  &.expired{
    opacity: 0.5;
    border: 2px solid lighten(#FF0000,40%);
  }


  &.clickable{
    cursor: pointer;
  }
  &.clickable:hover{
    // elevetion1
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
}