.recipe{
  h3{
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .subtitle{
    line-height: 1;
    font-style: italic;
  }
  .MuiAccordionDetails-root{
    border-top: 1px solid #DEDEDE;
  }
  .quantity,
  .ingredient{
    margin-left: 1rem;
    border-left: 3px solid #DEDEDE;
    padding-left: 0.5rem;
    margin-bottom: 0.4rem;

    font-size: 0.8rem;
  }
  .step{
    font-size: 0.8rem;
    position: relative;
    // text-align: left;
    .description{
      text-align: center;
    }
    .cook{
      text-align: center;
      background: #aaa;
      color: #FFF;
      text-align: center;
      padding: 0 0.5rem;
      border-radius: 5px;
      // text-transform: uppercase;
      font-size: 0.6rem;
      display: inline-block;
      .cookLine{

      }

      &.yaourtiere{
        background: #00b2d6;
      }
      &.other{
        background: #666;
      }
    }

    + .step{
      padding-top: 0.3rem;
      margin-top: 0.3rem;
      &:before{
        content: '>';
        display: block;
        color: #AAA;
        font-size: 0.8rem;
        line-height: 0.8rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: rotate(90deg) translateX(-50%);
        opacity: 0.6;
      }
    }
  }

  .steps{
    .stepCell{
      // display: inline-block;
      // vertical-align: middle;
    }
  }

  // .steps{
  //   white-space: nowrap;
  //   overflow-x: auto;
  // }
  // .step{
  //   display: inline-block;
  //   vertical-align: middle;
  //   margin-right: 0.5rem;
  //   .stepCell{
  //     display: inline-block;
  //     vertical-align: middle;
  //   }

    

  //   + .step:before{
  //     content: '>';
  //     display: inline-block;
  //     vertical-align: middle;
  //     margin-right: 0.5rem;
  //     color: #AAA;
  //     font-weight: bold;
  //     font-size: 1.2rem;
  //   }
  // }
}