.milktype{
  text-align: center;
  display: inline-block;
  vertical-align: top;
  .MuiSvgIcon-root{
    width: 1em;
    height: 1em;
    display: block;
    margin: 0 auto;
  }
  > span{
    font-size: 0.5rem;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
  }
  > svg,
  > span{
    &.red{
      color: red;
    }
    &.blue{
      color: blue;
    }
    &.green{
      color: green;
    }
    &.orange{
      color: orange;
    }
    &.lightblue{
      color: lightblue;
    }
  }

  &.size-small{
    .MuiSvgIcon-root{
      width: 0.8em;
      height: 0.8em;
    }
    > span{
      font-size: 0.4rem;
    }
  }
}