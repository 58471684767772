.tastetype{
  text-align: center;
  display: inline-block;
  // max-width: 40px;
  white-space: nowrap;
  .MuiSvgIcon-root{
    width: 0.7em;
    height: 0.7em;
    display: block;
    margin: 0 auto;
  }
  > span{
    font-size: 0.7em;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
    white-space: nowrap;
  }
}