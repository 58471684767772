$colors: (
  ('color-sachet',grey),
  ('color-petit_lait',grey),
  ('color-yaourt',#00b2d6),
  ('color-skyr',#00b25f),
  ('color-fromage',orange),
  ('color-dessert',saddlebrown),
  ('color-autre',grey)
);

.type{
  background: grey;
  color: #fff;
  padding: 0.2rem 0;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;

  &.vertical{
    display: inline-block;
    vertical-align: middle;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(-180deg);
    min-height: 40px;
    padding: 2px 4px;
    font-size: 0.6rem;
    text-align: center;
  }

  

  .tastetype{
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 1rem;
    text-transform: none;
    white-space: nowrap;
    max-width: 100%;
    .MuiSvgIcon-root{
      display: inline-block;
      vertical-align: middle;
      font-size: 0.7rem;
    }
    > span{
      display: inline-block;
      vertical-align: middle;
      font-size: 0.7rem;
    }
  }
  .appreciation{
    position: absolute;
    top: -1.2rem;
    right: 3px;
    font-size: 1rem;
    .MuiSvgIcon-root{
      font-size: 0.8em; 
    }
  }

  // variant archive
  &.small{
    padding: 0rem 0.3rem;
    font-size: 0.8rem;
    background: transparent;
    color: grey;
    .tastetype{
      text-align: left;
    }
  }

  // type
  @each $color in $colors {
    $colorClass: nth($color,1);
    $colorHexa: nth($color,2);
    &.#{$colorClass} {
      &:not(.small){
        background: $colorHexa;
      }
      &.small,
      .appreciation .MuiSvgIcon-root{
        color: $colorHexa;
      }
    }
    @if $colorClass == 'color-sachet' {
      &.#{$colorClass} {
        &:not(.small){
          background: linear-gradient(-45deg, lighten($colorHexa,40%) 12.5%, #fff 12.5%, #fff 37.5%, lighten($colorHexa,40%) 37.5%, lighten($colorHexa,40%) 62.5%, #fff 62.5%, #fff 87.5%, lighten($colorHexa,40%) 87.5%);
          background-size: 10px 10px;
          background-position: 50px 50px;
          color: $colorHexa;
        }
        &.small,
        .appreciation .MuiSvgIcon-root{
          color: $colorHexa;
        }
      }
    }
  }
}