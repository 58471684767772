.ferment-type{
  text-align: center;
  display: inline-block;
  vertical-align: top;
  position: relative;
  .MuiSvgIcon-root{
    width: 1em;
    height: 1em;
    display: block;
    margin: 0 auto; 
    color: grey;   

    &.reverse-v{
      transform: rotate(180deg);
    }

    // 2e svg en exposant
    + .MuiSvgIcon-root{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: inherit;
    }
  }
  > span{
    font-size: 0.5rem;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
  }

  &.size-small{
    .MuiSvgIcon-root{
      width: 0.8em;
      height: 0.8em;
    }
    > span{
      font-size: 0.4rem;
    }
  }
}