$modalBGcolor: #ffffff;

.modal-contents{
  background: $modalBGcolor;
  width: 80%;
  // height: 80%;
  max-width: 800px;
  border-radius: 4px;
  margin: 0 auto;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  max-height: 80vh;
  overflow-y: auto;

  &:focus{
    outline: 0;
  }

  &.modal-contents--edit{
    padding-top: 5rem;
  }


  h2{
    
  }
  h3{
    background: rgba(0,0,0,0.1);
  }

  .modal-form{
    margin-bottom: 50px;
  }
  .modal-actions{
    position: sticky;
    bottom: -1rem;
    width: 100%;
    background: rgba(255,255,255,0.8);
  }

  //
  .fieldRow{
    margin-top: 1rem;
  }


  // label par-dessus
  .MuiInputLabel-outlined{
    background: white;
    padding-bottom: 2px;
    width: calc( 100% - 28px );
    transform: translate(14px, 17px) scale(1);
    height: 20px;
    line-height: 20px;
  }

  // fields pleine largeur
  .MuiFormControl-root{
    // width: 100%;
    margin: 0.2rem;
  }
  // .MuiFormControl-fullWidth{
  //   width: 90%;
  // }

  .MuiSelect-root .MuiListItemIcon-root{
    font-size: 1em;
    min-width: 30px;
  }



  .accordion-no-paper{
    box-shadow: none;
    &:before{
      display: none;
    }
    .MuiAccordionSummary-root,
    .MuiAccordionDetails-root{
      padding: 0;
    }
    .accordion-content{
      width: 100%;
      .list-items{
        > *{
          margin: 0.2rem;
          display: inline-block;
        }
      }
    }
  }
}