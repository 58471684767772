$colors: (
  ('color-sachet',grey),
  ('color-petit_lait',grey),
  ('color-yaourt',#00b2d6),
  ('color-skyr',#00b25f),
  ('color-fromage',orange),
  ('color-dessert',saddlebrown),
  ('color-autre',grey)
);
.stat{
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  white-space: nowrap;
  line-height: 1rem;

  .count{
    display: inline-block;
    // vertical-align: middle;
    margin-right: 0.2rem;
    font-size: 1rem;
    font-weight: bold;
    width: 18px;
    text-align: right;
  }
  .label{
    display: inline-block;
    vertical-align: middle;
    background: lightgrey;
    color: #fff;
    font-size: 0.7rem;
    text-transform: uppercase;
    padding: 0 0.2rem;
    font-weight: bold;
  }

  &.zero{
    opacity: 0.3;
    font-style: italic;
  }


  @each $color in $colors {
    $colorClass: nth($color,1);
    $colorHexa: nth($color,2);
    &.#{$colorClass} {
      .count{
        color: $colorHexa;
      }
      .label{
        background: $colorHexa;
      }
    }
  }
  
}