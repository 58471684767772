.archives{
  .dluo{
    font-size: 0.6rem;
    white-space: nowrap;
    small{
      font-size: 0.6rem;
      line-height: 0.6rem;
      display: block;
    }
    span{
      font-size: 0.7rem;
      line-height: 0.8rem;
      display: block;
    }
  }
  .freeze{
    font-size: 0.9rem;
    line-height: 0.9rem;
    color: #3D8CFF; //#46eafc;
    .MuiSvgIcon-root{
      font-size: 0.9rem;
      line-height: 0.69rem;
    }
  }
  .lastDate{
    display: inline-block;
    vertical-align: middle;
    font-size: 0.9rem;
    line-height: 0.9rem;
    text-align: center;
    .day{
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
  .notes{
    opacity: 0.6;
    .MuiSvgIcon-root{
      font-size: 0.9em; 
    }
  }
  .appreciation{
    .MuiSvgIcon-root{
      font-size: 0.7em; 
    }
  }
  .statut{
    font-weight: bold;
    font-size: 0.7rem;
  }
  .available{
    
  }
  .archived{
    background: rgba(0,0,0,0.05);
    // box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.05) inset;
  }

  .MuiTableCell-sizeSmall{
    padding: 3px 8px 3px 8px;
  }

  .expired{
    color: red;
  }


  .MuiTableCell-head small{
    line-height: 1rem;
    display: block;
  }


  
  .stats{
    text-align: left;
    margin: 1rem;
    margin-top: 0;
    .MuiGrid-item{
      line-height: 1rem;
    }
  }

  .milktype{
    // transform: scale(0.9);
  }
  .milktype + .ferment{
    margin-left: 0.3rem;
  }
  .milktype,
  .ferment{
    vertical-align: top;
  }
}
    