.preparationCardArchive{
  display: inline-block;
  text-align: left;
  min-width: 100%;
  &.MuiPaper-root{
    background-color: transparent;
  }

  .type{
    // margin-right: 0.5rem;
  }
  .ingredients{
    transform: scale(0.9);
    text-align: left;
    transform-origin: top left;
    overflow: hidden;
  }
  .baseferment{
    max-width: 60px;
    margin-right: 2px;
    .name{
      font-size: 0.8rem; 
    }
    .increment{
      font-size: 0.6rem; 
    }
  }
  .milk{
    display: inline-block;
    vertical-align: middle;
    margin-left: 1px;
    margin-right: 1px;
  }
  .gout{
    display: inline-block;
    vertical-align: middle;
    margin-left: 1px;
    font-size: 0.9rem;
  }
  .badge-ferment{
    text-transform: uppercase;
    font-size: 0.5rem;
    padding: 0rem 0.3rem;
    color: white;
    // font-weight: bold;
    background: grey;
    display: inline-block;
    margin: 0rem 0.3rem;
  }

  &:hover{
    cursor: pointer;
  }
}