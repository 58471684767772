$colors: (
  ('color-sachet',grey),
  ('color-petit_lait',grey),
  ('color-yaourt',#00b2d6),
  ('color-skyr',#00b25f),
  ('color-fromage',orange),
  ('color-dessert',saddlebrown),
  ('color-autre',grey)
);
$preparationSize: 80px !important;

.preparation{
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: center;

  box-sizing: border-box;
  min-width: $preparationSize;
  max-width: $preparationSize;
  min-height: $preparationSize;
  max-height: $preparationSize;
  border-radius: $preparationSize;
  border: 2px solid transparent;
  padding: 0.6rem;

  .d-flex-v{
    height: calc( 80px - 1.2rem );
  }

  .name{
    font-size: 0.7rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
  }
  .taste{
    font-size: 0.8rem;
    color: #fff;
    white-space: nowrap;
    .tastetype{
      display: block;
      .MuiSvgIcon-root,
      > span{
        display: inline-block;
        vertical-align: middle;
        text-transform: none;
      }
      .MuiSvgIcon-root{
        display: inline-block;
        vertical-align: middle;
        width: 0.6em;
        height: 0.6em;
      }
    }
  }

  .dluo{
    margin-top: 0.2rem;
    font-size: 0.4rem;
    color: #fff;
    white-space: nowrap;
    small{
      font-size: 0.4rem;
      line-height: 0.4rem;
      display: block;
    }
    span{
      font-size: 0.5rem;
      line-height: 0.6rem;
      display: block;
    }
  }

  .ingredients{
    display: none;
  }

  .quantity{
    // display: none;        // pour inciter a conserver le nb de pot d'origine

    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0.6rem;
    line-height: 0.6rem;
    opacity: 0.8;

    border: 1px solid grey;
    border-radius: 50%;
    padding: 2px;
    background: #fff;
    min-width: 8px;
  }

  .freeze{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0.6rem;
    line-height: 0.6rem;
    opacity: 0.8;

    border: 1px solid #3D8CFF;
    border-radius: 50%;
    padding: 0px;
    background: #fff;
    min-width: 8px;
    color: #3D8CFF;
    .MuiSvgIcon-root{
      font-size: 0.6rem;
      line-height: 0.6rem;
    }
  }

  .id{
    font-size: 0.5rem;
    opacity: 0.7;
  }


  // type
  @each $color in $colors {
    $colorClass: nth($color,1);
    $colorHexa: nth($color,2);
    &.#{$colorClass} {
      background: $colorHexa;
    }
  }

  &.expired{
    opacity: 0.5;
    border: 2px solid lighten(#FF0000,40%);
  }


  &.clickable{
    cursor: pointer;
  }
  &.clickable:hover{
    // elevetion1
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
}