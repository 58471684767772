.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}

.float-right{
  float: right;
}
.float-left{
  float: left;
}

.abs-br{
  position: absolute !important;
  bottom: 0;
  right: 0;
  z-index: 2;
}


.mv-2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mh-1{
  margin-left: 1rem;
  margin-right: 1rem;
}
.mv-05{
  margin-top: 0.5rem;
  margin-bottom: 0rem;
}
.mb-1{
  margin-bottom: 1rem;
}
.mb-05{
  margin-bottom: 0.5rem;
}

.d-flex-v{
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.p-1{
  padding: 1rem;
}

.flex-1{
  flex: 1;
}


.nowrap{
  white-space: nowrap;
}


h2{
  font-size: 1.1rem;
}
h3{
  font-size: 1rem;
  margin: 0.5rem auto;
}


.absolute-top-right{
  position: absolute;
  top: 1rem;
  right: 1rem;
  .MuiFab-root + .MuiFab-root{
    margin-left: 0.5rem;
  }
}

.dateCell{
  min-width: 40px;
}