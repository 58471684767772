.preparationInEgouttage{
  padding: 0.2rem 0.5rem;
  text-align: center;

  .ingredients{
    display: flex;
    align-items: center;
    justify-content: center;
    > *{
      vertical-align: top;
    }
    > .ferment{
      .basename{
        margin-top: -0.2rem;
      }
    }
  }

  .id{
    font-size: 0.5rem;
    opacity: 0.7;
  }

  &.expired{
    opacity: 0.5;
    border: 2px solid lighten(#FF0000,40%);
  }


  &.clickable{
    cursor: pointer;
  }
  &.clickable:hover{
    // elevetion1
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
}