$color_background: #f0f0f0;
$color_text: #232323;
$color_secondary: rgba(0, 0, 0, 0.54);

.dashboard{
  &.MuiGrid-spacing-xs-2 {
  //   width: calc(100% + 24px);
  //   margin: -16px;
  //   margin-top: -8px;
  //   margin-bottom: 1rem;
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 0;
  }

  // text-align: center;
  background-color: $color_background;
  font-size: calc(10px + 2vmin);
  color: $color_text;
  // box-shadow: 2px 0px 5px rgba(0,0,0,0.2);

  &.MuiGrid-spacing-xs-2 > .MuiGrid-item{
    padding-left: 16px;
    padding-right: 16px;
    &.MuiGrid-grid-xs-6:nth-child(2),
    &.MuiGrid-grid-xs-8,
    &.MuiGrid-grid-xs-7{
      padding-right: 8px;
    }
    &.MuiGrid-grid-xs-6:nth-child(3),
    &.MuiGrid-grid-xs-4,
    &.MuiGrid-grid-xs-5{
      padding-left: 8px;
    }
  }

  // icones
  #congel{
    position: relative;
    overflow: hidden;
    > svg{
      display: block;
      position: absolute;
      top: -2rem; 
      left: -2rem;
      color: lightblue;
      font-size: 10rem;
      opacity: 0.3;
    }
  }
  #decongel{
    position: relative;
    overflow: hidden;
    > svg{
      display: block;
      position: absolute;
      top: -2rem; 
      left: -2rem;
      color: lightgrey;
      font-size: 10rem;
      opacity: 0.3;
    }
  }
  #frigo{
    position: relative;
    overflow: hidden;
    > svg{
      display: block;
      position: absolute;
      top: -0rem; 
      left: -2.5rem;
      color: lightgrey;
      font-size: 10rem;
      opacity: 0.3;
    }
  }
  #fermentation{
    position: relative;
    overflow: hidden;
    > svg{
      display: block;
      position: absolute;
      top: -0rem; 
      left: -1rem;
      color: lightgrey;
      font-size: 6rem;
      opacity: 0.3;
    }
  }
  #egouttage{
    position: relative;
    overflow: hidden;
    > svg{
      display: block;
      position: absolute;
      top: -0rem; 
      left: -1rem;
      color: lightgrey;
      font-size: 6rem;
      opacity: 0.3;
    }
  }

  .wrapper{
    overflow: hidden;
  }

  .fullDimension{
    min-height: 100%;
    padding: 1rem;
    padding-bottom: 3rem;
    box-sizing: border-box;
  }

  // legend DLUO
  .legend{
    position: absolute;
    font-size: 0.9rem;
    color: #333;
    white-space: nowrap;
    bottom: 0.2rem;
    right: 1rem;
    color: $color_secondary;
    opacity: 0.4;
    font-style: italic;
  }

  .MuiPaper-root{
    position: relative;
    > .MuiTypography-root{
      opacity: 0.7;
      margin-bottom: 0.5rem;
    }
    &#fermentation2{
      opacity: 0.5;
    }
    // picto +
    .addlegend{
      margin: 0rem auto;
      margin-top: 0.2rem;
      font-size: 2rem;
      line-height: 2rem;
      display: block;
      cursor: pointer;
      position: absolute;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      .MuiSvgIcon-root{
        font-size: 1em;
        color: $color_secondary;
        opacity: 0.4;
        transition: 0.3s;
      }
      &:hover{
        .MuiSvgIcon-root{
          opacity: 0.6;
        }
      }
      &.centered{
        margin-top: 4rem;
        @media screen and (max-width: 560px){
          margin-top: 1rem;
        }
      }
      &.dragging .MuiSvgIcon-root{
        opacity: 1;
        color: $color_text;
      }
    }
  }

  
  .flexer{
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    .MuiCard-root{
      flex: 1;
      margin: 0.5rem;
      min-width: 150px;
      max-width: 250px;
      display: flex;
      flex-direction: column;
      // justify-items: space-between;
      .dluo{
        flex: 1;
      }
    }
    &.flexer-fermentation,
    &.flexer-egouttage{
      .MuiCard-root{
        min-width: 100px;
        @media screen and (min-width: 560px){
          min-width: 150px;
        }
      }
    }
    &.flexer-frigo,
    &.flexer-congel,
    &.flexer-decongel{
      .MuiCard-root{
        @media screen and (max-width: 560px){
          max-width: 50%;
        }
      }
    }
  }

  .list-items{
    width: 100%;
    > .MuiPaper-root{
      margin: 0.2rem;
    }
  }
}